@import 'primeng/resources/primeng.css';
@import '@pv-frontend/pv-shared-components/resources/styles/theme.css';
@import '@pv-frontend/pv-shared-components/resources/styles/primeflex.css';
@import '@pv-frontend/pv-shared-components/resources/styles/custom-theme.scss';
@import './assets/variables.scss';

:root {
  --white: #e1e6f0;
  --background1: #16181f;
  --background2: #0f1014;
  --primary1: #095ae5;
  --primary2: #043ec7;
  --errorState: #ff4538;
  --pale-yellow: #ffe2a8;
  --grayish-blue: #707a94;
  --blue-gray: #8f98b2;
  --dark-gray: #252833;
  --light-blue: #cad2e5;
  --orange-brown: #bd7100;
  --disable-btn-bg: #202228;
  --color-blue: #062794;
  --color-cyan: #0fffff;
}

html,
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
}

@media only screen and (max-width: 992px) {
  html,
  body {
    font-size: 14px;
  }
}

.p-button {
  border-radius: 8px;
  height: 44px;
}

.p-button.p-button-raised {
  background: linear-gradient(
    93.87deg,
    var(--primary1) 0%,
    var(--primary2) 100%
  );
}

.p-button:disabled {
  background: var(--disable-btn-bg);
  background-color: var(--surface-800) !important;
}

.pv-mobile-input-container {
  width: 100%;
}

.white-stroke-svg {
  stroke: var(--surface-0);
}

.pv-otp-input-ele.is-invalid-otp {
  border: 1px solid var(--errorState);
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.heading-text {
  color: var(--white);
}

.not-expired {
  color: var(--light-blue);
}

.expired-or-expiring {
  color: var(--orange-brown);
}

.terms .p-panel .p-panel-header {
  border-radius: 0;
}

.overflow-wrap-word {
  overflow-wrap: break-word;
  hyphens: auto;
}
